const tenant_settings = {
    title: 'Impostazioni Tenant',
    description: 'Cambia le impostazioni del tuo account e gestisci le tue informazioni personali qui per garantire la sicurezza del tuo account.',
    tabs: {
        settings: 'Impostazioni',
        domains: 'Domini',
    },
    profile: {
        title: 'IMPOSTAZIONI DEL PROFILO',
        tenant_id: 'ID Tenant',
        tenant_name: 'Nome Tenant',
        environment_tag: 'Tag Ambiente',
        environment_tag_description: "Utilizza i tag per differenziare gli ambienti di utilizzo del tenant. I servizi all'interno di ogni tag sono identici, garantendo la coerenza.",
        environment_tag_development: 'Sviluppo',
        environment_tag_staging: 'Staging',
        environment_tag_production: 'Produzione',
    },
};
export default tenant_settings;
