const tenant_settings = {
    title: 'Definições de Inquilino',
    description: 'Mude suas configurações de conta e gerencie suas informações pessoais aqui para garantir a segurança de sua conta.',
    tabs: {
        settings: 'Configurações',
        domains: 'Domínios',
    },
    profile: {
        title: 'CONFIGURAÇÕES DE PERFIL',
        tenant_id: 'ID do Inquilino',
        tenant_name: 'Nome do Inquilino',
        environment_tag: 'Tag de Ambiente',
        environment_tag_description: 'Use tags para diferenciar os ambientes de uso do inquilino. Os serviços dentro de cada tag são idênticos, garantindo consistência.',
        environment_tag_development: 'Desenvolvimento',
        environment_tag_staging: 'Staging',
        environment_tag_production: 'Produção',
    },
};
export default tenant_settings;
