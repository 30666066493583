const contact = {
    title: '获取帮助',
    description: '有关帮助请求或产品反馈，请通过以下方式与我们联系。',
    discord: {
        title: '加入 Discord 社区',
        description: '加入我们的公共频道与其他开发人员聊天',
        button: '加入',
    },
    github: {
        title: '创建 GitHub 问题',
        description: '创建问题并在 GitHub 提交',
        button: '打开',
    },
    email: {
        title: '通过邮件联系支持',
        description: '发送电子邮件以获取更多信息和帮助',
        button: '发送',
    },
};
export default contact;
