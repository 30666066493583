const tenant_settings = {
    title: 'Ustawienia Najemcy',
    description: 'Zmieniaj swoje ustawienia konta i zarządzaj swoimi informacjami osobistymi tutaj, aby zapewnić bezpieczeństwo Twojego konta.',
    tabs: {
        settings: 'Ustawienia',
        domains: 'Domeny',
    },
    profile: {
        title: 'USTAWIENIA PROFILU',
        tenant_id: 'ID Najemcy',
        tenant_name: 'Nazwa Najemcy',
        environment_tag: 'Tag Środowiska',
        environment_tag_description: 'Użyj tagów, aby rozróżnić środowiska użytkowe najemcy. Usługi w każdym tagu są identyczne, co zapewnia spójność.',
        environment_tag_development: 'Rozwój',
        environment_tag_staging: 'Staging',
        environment_tag_production: 'Produkcja',
    },
};
export default tenant_settings;
