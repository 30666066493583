const tenant_settings = {
    title: 'Настройки арендатора',
    description: 'Измените настройки своей учетной записи и управляйте своей личной информацией здесь, чтобы обеспечить безопасность своей учетной записи.',
    tabs: {
        settings: 'Настройки',
        domains: 'Домены',
    },
    profile: {
        title: 'НАСТРОЙКА ПРОФИЛЯ',
        tenant_id: 'ID арендатора',
        tenant_name: 'Имя арендатора',
        environment_tag: 'Тег окружения',
        environment_tag_description: 'Используйте теги для различения окружений использования арендаторов. Сервисы в каждом теге идентичны, обеспечивая согласованность.',
        environment_tag_development: 'Разработка',
        environment_tag_staging: 'Стадия',
        environment_tag_production: 'Производство',
    },
};
export default tenant_settings;
