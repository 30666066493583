const contact = {
    title: 'Ottieni Aiuto',
    description: 'Per richieste di assistenza o commenti sui prodotti, contattaci tramite i seguenti metodi.',
    discord: {
        title: 'Unisciti alla comunità di Discord',
        description: 'Unisciti al nostro canale pubblico per chattare con altri sviluppatori',
        button: 'Unisciti',
    },
    github: {
        title: 'Crea problema su GitHub',
        description: 'Crea un problema e invialo su GitHub',
        button: 'Apri',
    },
    email: {
        title: 'Contattare il supporto tramite e-mail',
        description: 'Invia una e-mail per ulteriori informazioni e assistenza',
        button: 'Invia',
    },
};
export default contact;
