const contact = {
    title: 'Zdobądź pomoc',
    description: 'W przypadku żądań pomocy lub opinii na temat produktu, skontaktuj się z nami za pomocą następujących metod.',
    discord: {
        title: 'Dołącz do społeczności Discorda',
        description: 'Dołącz do naszego kanału publicznego, aby porozmawiać z innymi programistami',
        button: 'Dołącz',
    },
    github: {
        title: 'Utwórz problem w GitHub',
        description: 'Utwórz problem i zgłoś w GitHub',
        button: 'Otwórz',
    },
    email: {
        title: 'Skontaktuj się z pomocą techniczną przez e-mail',
        description: 'Wyślij do nas e-maila w celu uzyskania dalszych informacji i pomocy',
        button: 'Wyślij',
    },
};
export default contact;
