const domain = {
    status: {
        connecting: 'Подключение',
        in_used: 'Используется',
        failed_to_connect: 'Не удалось подключиться',
    },
    update_endpoint_alert: {
        description: 'Ваш собственный домен был успешно настроен. Не забудьте обновить домен, который вы использовали для <span>{{domain}}</span>, если ранее настраивали следующие ресурсы.',
        endpoint_url: 'URL конечной точки <a>{{link}}</a>',
        application_settings_link_text: 'Настройки приложения',
        callback_url: 'URL обратного вызова <a>{{link}}</a>',
        social_connector_link_text: 'Социальный коннектор',
        api_identifier: 'Идентификатор API <a>{{link}}</a>',
        uri_management_api_link_text: 'API управления URI',
        tip: 'После изменения настроек вы можете протестировать это в нашем процессе входа <a>{{link}}</a>.',
    },
    custom: {
        custom_domain: 'Пользовательский домен',
        custom_domain_description: 'Замените домен по умолчанию на свой собственный домен, чтобы поддерживать согласованность с вашим брендом и персонализировать процесс входа для ваших пользователей.',
        custom_domain_field: 'Пользовательский домен',
        custom_domain_placeholder: 'your.domain.com',
        add_domain: 'Добавить домен',
        invalid_domain_format: 'Некорректный формат поддомена. Введите поддомен с минимум 3 секциями.',
        verify_domain: 'Проверить домен',
        enable_ssl: 'Включить SSL',
        checking_dns_tip: 'После настройки DNS, процесс запустится автоматически и может занять до 24-х часов. Вы можете выйти из этого интерфейса во время его работы.',
        generating_dns_records: 'Генерация DNS записей...',
        add_dns_records: 'Пожалуйста, добавьте следующие DNS-записи в Ваш DNS-провайдер.',
        dns_table: {
            type_field: 'Тип',
            name_field: 'Имя',
            value_field: 'Значение',
        },
        deletion: {
            delete_domain: 'Удалить домен',
            reminder: 'Удалить пользовательский домен',
            description: 'Вы уверены, что хотите удалить этот пользовательский домен?',
            in_used_description: 'Вы уверены, что хотите удалить этот пользовательский домен "<span>{{domain}}</span>"?',
            in_used_tip: 'Если вы ранее настройте этот пользовательский домен в своём провайдере социальных коннекторов или конечной точке приложения, Вам нужно сначала изменить URI на домен по умолчанию Logto "<span>{{domain}}</span>". Это необходимо для правильной работы кнопки социальной авторизации.',
            deleted: 'Пользовательский домен успешно удалён!',
        },
    },
    default: {
        default_domain: 'Домен по умолчанию',
        default_domain_description: 'Мы предоставляем доменное имя по умолчанию, которое можно использовать напрямую в Интернете. Оно всегда доступно, обеспечивая доступ к вашему приложению для входа всегда, даже если вы переключитесь на пользовательский домен.',
        default_domain_field: 'Домен по умолчанию Logto',
    },
};
export default domain;
