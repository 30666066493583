const contact = {
    title: 'Hilfe erhalten',
    description: 'Für Hilfsanfragen oder Produktfeedback wenden Sie sich bitte über die folgenden Methoden an uns.',
    discord: {
        title: 'Trete der Discord-Community bei',
        description: 'Treten Sie unserem öffentlichen Kanal bei, um mit anderen Entwicklern zu chatten',
        button: 'Beitreten',
    },
    github: {
        title: 'Erstelle ein GitHub-Problem',
        description: 'Erstelle ein Problem und sende es an GitHub',
        button: 'Öffnen',
    },
    email: {
        title: 'Unterstützung per E-Mail kontaktieren',
        description: 'Senden Sie uns eine E-Mail für weitere Informationen und Hilfe',
        button: 'Senden',
    },
};
export default contact;
